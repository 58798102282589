import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import type { Icon } from '@phosphor-icons/react';
import { colors } from 'styles/theme/colors';

type MenuItemProps = {
  url: string;
  MenuIcon: Icon;
  children: React.ReactNode;
  topPadding?: boolean;
  selected?: boolean;
  onClick: () => void;
  extend: boolean;
};

export const MenuItem = ({
  url,
  MenuIcon,
  topPadding = false,
  selected = false,
  children,
  onClick,
  extend,
}: MenuItemProps) => {
  return (
    <Link to={url} onClick={onClick}>
      <Item topPadding={topPadding} selected={selected} extend={extend}>
        <IconCon>
          <MenuIcon
            weight={selected ? 'fill' : undefined}
            color={selected ? colors.mintGreen[900] : undefined}
          />
        </IconCon>
        {extend ? <Text>{children}</Text> : null}
      </Item>
    </Link>
  );
};

const Item = styled.div<{ topPadding: boolean; selected: boolean; extend: boolean }>`
  width: ${p => (p.extend ? 200 : 40)}px;
  display: flex;
  gap: 8px;
  align-items: center;
  flex-direction: row;
  height: 40px;
  ${p => (p.topPadding ? 'margin-top: 40px;' : '')}
  ${p => (p.selected ? `background-color: ${p.theme.palette.utility.mintGreen[400]};` : '')}
  border-radius: 8px;
  :hover {
    background-color: ${p => p.theme.palette.utility.mintGreen[100]};
  }
`;

const IconCon = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
`;

const Text = styled.span`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 40px;
`;
