import styled from '@emotion/styled';
import { useLogout, useUser } from 'features/auth';
import { useNavigate } from 'react-router-dom';
import { BaseButton } from 'components';
import { Avatar } from 'components/avatar';

export const UserDetails = () => {
  const { data } = useUser({});
  const navigate = useNavigate();
  const logout = useLogout({ onSuccess: () => navigate('/') });

  const onLogout = () => {
    logout.mutate({});
  };

  return (
    <Container>
      <Greeting>
        <span>Good to see you, {data?.loginContext?.name}</span>
        <span role="img" aria-label="partying">
          &#129395;
        </span>
      </Greeting>
      <BaseButton onClick={() => onLogout()}>
        <Avatar letters={data?.loginContext?.name} />
      </BaseButton>
    </Container>
  );
};

const Greeting = styled.span`
  color: ${p => p.theme.palette.utility.black};
  align-items: center;
  display: flex;
  gap: 0.25rem;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;
  span[role='img'] {
    font-size: 1.2rem;
  }
`;
