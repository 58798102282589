import styled from '@emotion/styled';
import { Topbar } from 'components/topbar';
import { Sidebar } from 'components/sidebar';

export const Layout = ({ children }: { children: React.ReactNode }) => {
  return (
    <LayoutWrapper>
      <Topbar />
      <Sidebar>
        <main>
          <Page>{children}</Page>
        </main>
      </Sidebar>
    </LayoutWrapper>
  );
};

const Page = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  background-color: ${p => p.theme.palette.bg.primary};
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const LayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;

  main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    background-color: #fbf9f7;
    padding: 16px;
    padding-bottom: 0;
    height: calc(100vh - 72px);
    overflow-x: hidden;
    overflow-y: auto;
  }
`;
