import { useLingui } from '@lingui/react';
import { wordList } from './wordlist';
import { useStoreState } from 'stores/global';

export type Children = keyof typeof wordList;

type JargonProps = {
  text: Children;
};

export const Jargon = ({ text }: JargonProps) => {
  const { _ } = useLingui();
  const { orgType } = useStoreState(state => state.me);

  if (!isInRange(orgType, 1, 7)) {
    return text;
  }

  return <>{_(wordList[text][orgType - 1])}</>;
};

const isInRange = (value: number, min: number, max: number): value is number => {
  return value >= min && value <= max;
};
