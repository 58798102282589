import styled from '@emotion/styled';
import { Jargon } from 'components';
import { MenuItem } from './MenuItem';
import { UserDetails } from './UserDetails';
import { OldSiteLink } from './OldSiteLink';

export const Header = () => {
  return (
    <HeaderWrapper>
      <Menu>
        <List>
          <MenuItem url="/taskboard">
            <Jargon text="Taskboard" />
          </MenuItem>
          <MenuItem url="/dashboard">
            <Jargon text="Dashboard" />
          </MenuItem>
          <MenuItem url="/style">
            <Jargon text="Styles" />
          </MenuItem>
          <MenuItem url="/item">
            <Jargon text="Items" />
          </MenuItem>
          <MenuItem url="/report">
            <Jargon text="Reports" />
          </MenuItem>
          <MenuItem url="/analytics">
            <Jargon text="Analytics" />
          </MenuItem>
          <MenuItem url="/marketing">
            <Jargon text="Marketing" />
          </MenuItem>
          <MenuItem url="/order">
            <Jargon text="Orders" />
          </MenuItem>
          <MenuItem url="/compliance">
            <Jargon text="Compliance" />
          </MenuItem>
          <MenuItem url="/admin">
            <Jargon text="Admin" />
          </MenuItem>
          <MenuItem url="/example/playground">Example</MenuItem>
        </List>
        <OldSiteLink />
        <UserDetails />
      </Menu>
    </HeaderWrapper>
  );
};

const HeaderWrapper = styled.header`
  background-color: #f5f5f5;
`;

const Menu = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const List = styled.ul`
  display: flex;
  list-style: none;
  padding-left: 0;
  margin-block: 0;
  flex-wrap: wrap;
`;
